import React from "react";
import "../Pages/OnlineFriends/OnlineFriends.scss";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useMediaQuery } from "react-responsive";
import { Modal } from "reactstrap";
import { useNavigate } from "react-router-dom";
import axiosInstanceAuth from "../apiServices/axiosInstanceAuth";

const TimerModal = ({
  open,
  close,
  cancel = null,
  duration,
  renderTime,
  userName,
  isShow = false,
}) => {
  const isWide = useMediaQuery({ minWidth: "500px" });
  const navigate = useNavigate();

  const getsStopTimer = async () => {
    await axiosInstanceAuth
      .get(`quiz/stop-timer/${localStorage.getItem("quiz_id")}`)
      .then((res) => {
        if (res?.data?.status) {
          navigate("/activity-log");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Modal
        className="waiting-user-modal"
        style={{ display: isShow ? "none" : "flex" }}
        autoFocus={true}
        centered={true}
        backdrop="static"
        isOpen={open}
      >
        <div className="waiting-user-wrapper">
          {duration <= 3 && (
            <div className="close-icon-wrapper">
              {
                cancel === null ? null : <img
                onClick={cancel === null ? close : cancel}
                src={require("../Assets/Img/close-icon.svg").default}
                alt=""
                className="img-fluid"
              />
              }
              
            </div>
          )}
          <h1 className="waiting-title">
            {cancel === null
              ? `Challenge accepted`
              : `Waiting for ${userName} to accept your challenge`}
          </h1>
          <p className="starting-title">
            {cancel === null && `Starting in...`}
          </p>
          <div className="d-flex justify-content-center align-items-center">
            <div className="count-down-timer-wrapper">
              <CountdownCircleTimer
                isPlaying
                duration={duration}
                size={isWide ? 180 : 150}
                colors="#1A2B51"
                onComplete={() => [true, 1000]}
              >
                {renderTime}
              </CountdownCircleTimer>
            </div>
          </div>
          <div className="text-center">
            {duration <= 3 ? (
              <>{
                cancel === null ? null : <button onClick={close} className="cancel-btn">
                Cancel
              </button>
              }
               
              </>
             
            ) : (
              <div className="d-flex justify-content-around">
                <button onClick={close} className="withdraw-btn">
                  Withdraw
                </button>
                <button onClick={getsStopTimer} className="cancel-btn">
                  Play later
                </button>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TimerModal;
