import moment from "moment";
import React, { useEffect, useId, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal } from "reactstrap";
import axiosInstanceAuth from "../../apiServices/axiosInstanceAuth";
import useEncryption from "../../customHook/useEncryption";
import TimerModal from "../../Modal/TimerModal";
import "./Notification.scss";
import "../../Pages/ActivityLog/activityLog.scss";

const Notification = () => {
  const { decryptData, encryptData } = useEncryption();
  const [notification, setNotification] = useState({});
  const [selected, setSelected] = useState({});
  const [isDecline, setIsDecline] = useState(false);
  const [isModal, SetIsModal] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  let userData = decryptData(localStorage.getItem("user"));
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const getNotification = async () => {
    await axiosInstanceAuth
      .get("notification/latest")
      .then((res) => {
        const data = decryptData(res.data.data);
        if (res?.data?.status) {
          localStorage.setItem("read_flag", res?.data?.read_flag);
          if (data[0]) {
            var endDate = new Date(
              new Date(data[0].request_time).getTime() + 60 * 60 * 24 * 1000
            );
            var end_date = moment(endDate);
            var start_date = moment(
              moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
            );
            var hours = moment.duration(end_date.diff(start_date)).get("hours");
            var minutes = moment
              .duration(end_date.diff(start_date))
              .get("minutes");
            var notificationTime = { hours: hours, minutes: minutes };
            setNotification({ ...data[0], notificationTime });

            setIsNotification(true);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const acceptRequest = async (user, userName, userImg, country) => {
    setIsNotification(false);
    localStorage.setItem(
      "topicNameIcon",
      encryptData(
        JSON.stringify({
          name: user.topic_name,
          color: user.topic_color_code,
          icon: user.topic_icon,
        })
      )
    );
    user.userName = userName;
    user.userImg = userImg;
    user.type = "accept";
    user.country_flag = country;
    localStorage.setItem("quiz_id", user.quiz_id);
    setSelected(user);
    SetIsModal(true);
    // localStorage.setItem("IsModal",true)
  };

  const onStartGame = async () => {
    localStorage.removeItem("spent_time");
    localStorage.removeItem("allQuestions");
    localStorage.removeItem("currentInd");
    if (selected.type === "accept") {
      const encryptedData = encryptData(
        JSON.stringify({
          quiz_id: selected.quiz_id,
        })
      );
      await axiosInstanceAuth
        .post("quiz/accept-request", {
          data: encryptedData,
        })
        .then((res) => {
          const data = decryptData(res.data.data);
          if (res?.data?.status) {
            // getQuizQuestions()
            SetIsModal(false);
            setIsWaiting(true);
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          toast.error(err);
        });
    } else if (selected.type === "play") {
      const encryptedData = encryptData(
        JSON.stringify({
          quiz_id: selected.quiz_id,
        })
      );
      await axiosInstanceAuth
        .post("quiz/play-requested-quiz", {
          data: encryptedData,
        })
        .then((res) => {
          const data = decryptData(res.data.data);
          if (res?.data?.status) {
            // getQuizQuestions()
            SetIsModal(false);
            setIsWaiting(true);
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  const declineRequest = async () => {
    const encryptedData = encryptData(
      JSON.stringify({
        quiz_id: selected.quiz_id,
      })
    );
    await axiosInstanceAuth
      .post("quiz/decline-request", {
        data: encryptedData,
      })
      .then((res) => {
        const data = decryptData(res.data.data);
        if (res?.data?.status) {
          setIsDecline(false);
        } else {
          toast.error(res?.data?.message);
          setIsDecline(false);
        }
      })
      .catch((err) => {
        setIsDecline(false);
        toast.error(err);
      });
  };

  // *** time count ***
  const formatRemainingTime = (time) => {
    var seconds = time % 60;
    return `${seconds}`;
  };

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      setIsWaiting(false);
      // setIsPending(true)
      navigate("/question", { state: true });
    } else {
      return (
        <div className="time-count-second">
          {formatRemainingTime(remainingTime)}
        </div>
      );
    }
  };
  useEffect(() => {
    const auth = localStorage.getItem("token");
    var res = pathname.charAt(pathname.length-1);
    const interval = setInterval(() => {
      if(pathname === "/account-type"){
      } else if(pathname === `/interested${res}`){
      }
      else {
        if (auth) {
          getNotification();
        }
      }
     
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  });


  return (
    <>
      <div
        className={`${
          isNotification ? "d-block" : "d-none"
        } notification_wrapper`}
      >
        <div className="topic_wrapper">
          <img
            className="topic_image"
            src={notification?.topic_icon}
            alt="logo"
          />
          <h1
            className="topic_name"
            style={{ color: notification?.topic_color_code }}
          >
            {notification?.topic_name}
          </h1>
          <div className="lt-wrapper-loss-won">
            <span className="loss_learning_token">
              {notification?.spent_learning_token}
            </span>
            <img
              src={require("../../Assets/Img/declined-lt-img.svg").default}
              alt=""
              className="img-fluid"
            />
            <span className="won_learning_token">
              {notification?.win_learning_token}
            </span>
          </div>
        </div>
        <div className="challenge_wrapper">
          <h5 className="challenge_title">Challenge received</h5>
          <div className="button_wrapper">
            <button
              disabled={pathname === "/question"}
              className="accept_button"
              onClick={() =>
                acceptRequest(
                  notification,
                  notification?.sender_user_name,
                  notification?.sender_avatar,
                  notification?.sender_country_flag
                )
              }
            >
              Accept
            </button>
            <button
              className="play_later_button"
              onClick={() => {
                setIsNotification(false);
                setNotification({});
              }}
            >
              Play Later
            </button>
            <button
              className="decline_button"
              onClick={() => {
                setSelected(notification);
                setIsDecline(true);
                setIsNotification(false);
              }}
            >
              Decline
            </button>
          </div>
        </div>
        <div className="opponent_name_wrapper">
          <h4 className="opponent_name">
            vs. {notification?.sender_user_name}
          </h4>
          <h4 className="opponent_time">
            {notification?.notificationTime?.hours}h{" "}
            {notification?.notificationTime?.minutes}m left
          </h4>
        </div>
      </div>
      {isModal && (
        <Modal
          className="won-lost-modal-activityModal"
          autoFocus={true}
          centered={true}
          backdrop="static"
          isOpen={isModal}
        >
          <div className="won-lost-contend">
            <div className="close-icon-wrapper">
              <img
                onClick={() => SetIsModal(false)}
                src={require("../../Assets/Img/close-icon.svg").default}
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="modal-root">
              <div className="won-lost-contend-start">
                <img src={selected.topic_icon} alt="" className="img-fluid" />
                <h1
                  style={{ color: selected.topic_color_code }}
                  className="wen-lost-title"
                >
                  {selected.topic_name}
                </h1>
              </div>
              <div className="challenge-img-wrapper">
                <img
                  src={userData.avatar}
                  alt=""
                  className="img-fluid user-first-img"
                />
                <img
                  src={require("../../Assets/Img/lineLogo.svg").default}
                  alt=""
                  className="img-fluid logo"
                />
                <img
                  src={selected.userImg}
                  alt=""
                  className="img-fluid user-first-img"
                />
              </div>
              <div className="title-wrapper">
                <div className="">
                  <div className="d-flex justify-content-between">
                    <p className="user">{userData.user_name}</p>
                    <img
                      src={userData?.country_flag}
                      alt=""
                      className="img-fluid country-logo"
                    />
                  </div>
                </div>
                <div className="">
                  <div className="d-flex justify-content-between">
                    <p className="user">{selected.userName}</p>
                    <img
                      src={selected?.country_flag}
                      alt=""
                      className="img-fluid country-logo"
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex justify-content-center">
                  <button className="start-button" onClick={onStartGame}>
                    START
                    <img
                      src={require("../../Assets/Img/book-white.svg").default}
                      alt=""
                      className="img-fluid"
                    />
                    - 5
                  </button>
                </div>
                <div className="details-wrapper-reward d-flex justify-content-center">
                  <div className="reward-box">
                    <div className="d-flex justify-content-center align-items-center">
                      <img
                        src={require("../../Assets/Img/book-icon.svg").default}
                        alt=""
                        className="img-fluid"
                      />
                      <p className="reward">+ 9 Reward</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {isWaiting && (
        <TimerModal
          userName={selected.userName}
          open={isWaiting}
          close={() => setIsWaiting(false)}
          duration={3}
          renderTime={renderTime}
        />
      )}
      {isDecline && (
        <Modal
          className="activity-delete-modal"
          autoFocus={true}
          centered={true}
          backdrop="static"
          isOpen={isDecline}
        >
          <div className="activity-wrapper">
            <div className="close-icon-wrapper">
              <img
                onClick={() => setIsDecline(false)}
                src={require("../../Assets/Img/close-icon.svg").default}
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="activity-contend">
              <div className="d-flex justify-content-center align-items-center">
                <img
                  src={require("../../Assets/Img/decline.svg").default}
                  alt=""
                  className="activity-image"
                />
              </div>
              <h2></h2>
              <div className="activity-details">
                <p>
                  Are you sure you want to decline the challenge in
                  <span style={{ color: selected.topic_color_code }}>
                    &nbsp;{selected.topic_name}&nbsp;
                  </span>
                  from<b>&nbsp;{selected.sender_user_name}</b>?
                </p>
              </div>
              <div className="activity-button-wrapper">
                <button
                  onClick={() => {
                    setIsDecline(false);
                    setSelected({});
                  }}
                  className="cancel-btn"
                >
                  Cancel
                </button>
                <button onClick={declineRequest} className="delete-btn">
                  Decline
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Notification;
